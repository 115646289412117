import renderDriveServiceRows from "../assets/render/renderDriveServiceRows";
import renderRawContent from "../assets/render/renderRawContent";
import installDriveServiceScripts from "../assets/store/installDriveServiceScripts";
import installVersion4ScriptTag from "../assets/store/installVersion4ScriptTag";
import installStyleSheetLink from "../assets/store/installStyleSheetLink";
import installStyles from "../assets/store/installStyles";
import installStyleTemplate from "../assets/store/installStyleTemplate";
import installGoogleTagManager from "../assets/store/installGoogleTagManager";
import {
    getDemoWeatherOverride,
    getDemoLayoutOverride,
    getDemoColumnOverride,
    getDemoSocialShareOverride,
    getConversionCouponOverride,
    getDemoHoveredFirstButton,
} from "../assets/store/getDemoOverrides";
import { DeviceDetect } from "../assets/store/deviceDetect";
import { checkForHomepageWidget } from "../assets/store/checkForHomepageWidget";

const loadDriveServiceDivs = () => {
    const deviceDetect = new DeviceDetect({ isTest: false });
    try {
        const drive_service_divs = document.getElementsByClassName(
            "drive-service-specials-v5"
        );
        let drive_service_div_ids = [];
        let weatherOverrideObjects = [];
        let couponButtonLayoutOverride,
            useThreeCouponColumns,
            useSocialShare,
            hoveredFirstCta;
        for (let index = 0; index < drive_service_divs.length; index++) {
            const element = drive_service_divs[index];
            let weatherOverride = getDemoWeatherOverride(element);
            couponButtonLayoutOverride = getDemoLayoutOverride(element);
            useThreeCouponColumns = getDemoColumnOverride(element);
            useSocialShare = getDemoSocialShareOverride(element);
            hoveredFirstCta = getDemoHoveredFirstButton(element);
            const id = element.id.replace("ds-div-", "");
            if (weatherOverride)
                weatherOverrideObjects.push({
                    dsDivId: id,
                    effect:
                        weatherOverride.indexOf("rain") !== -1
                            ? "rain"
                            : "snow",
                });
            drive_service_div_ids.push(Number(id));
        }
        if (drive_service_div_ids.length > 0) {
            let couponOverride = getConversionCouponOverride();
            const body = JSON.stringify({
                href:
                    window.location.host === "localhost:3000"
                        ? "https://www.specialsmanagement.com"
                        : window.location.href,
                divs: drive_service_div_ids,
                weather_overrides: weatherOverrideObjects.length
                    ? weatherOverrideObjects
                    : null,
                coupon_widget_override: couponOverride,
                coupon_button_layout: couponButtonLayoutOverride,
                use_three_coupon_columns: useThreeCouponColumns,
                use_social_share: useSocialShare,
                hovered_first_cta: hoveredFirstCta,
                languageOption: localStorage.getItem("languageOption"),
            });
            fetch(
                window.location.host === "localhost:3000" ||
                    window.location.host === "www.specialsmanagement.com"
                    ? process.env.REACT_APP_DEMO_DS_DIV_API
                    : process.env.REACT_APP_DS_DIV_API,
                {
                    method: "POST",
                    mode: "cors",
                    cache: "default",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: body,
                }
            )
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return Promise.reject(res);
                })
                .then((data) => {
                    window.DriveServiceSpecials = data;
                    let { drive_service_divs: ds_divs } = data;
                    if (data) {
                        renderDriveServiceRows(data, drive_service_divs);
                        renderRawContent();
                        if (!checkForHomepageWidget(ds_divs))
                            installStyles(data);
                        if (process.env.REACT_APP_ENVIRONMENT !== "local") {
                            installDriveServiceScripts();
                        } else {
                            window.loadDevEnvironment();
                        }
                        // installVersion4ScriptTag();
                    } else {
                        throw new Error("No data received.");
                    }
                    // Global function to reload rows
                    if (window.reloadRows) window.reloadRows();
                })
                .catch((error) => {
                    console.log("Something went wrong: " + error);
                });
        }
    } catch {
        console.log("Page did not load. Please try again later.");
    }
};
installGoogleTagManager();
loadDriveServiceDivs();
window.loadDriveServiceDivs = loadDriveServiceDivs;

export default loadDriveServiceDivs;
